import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function MuPage() {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        // Fetch books where m_book_category_id = 4
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/books?bookCategory=4`);
        setBooks(response.data);
      } catch (error) {
        console.error('Error fetching the books:', error);
      }
    };

    fetchBooks();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen p-5">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6">
        {books.map((book) => (
          <Link
            to={`/contents/${book.slug}`}
            key={book.id}
            className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
          >
            <img
              src={book.image_cover}
              alt={book.name}
              className="w-full object-cover"
            />
            <div className="p-4">
              <h2 className="text-lg font-semibold">{book.name}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default MuPage;
